<template>
  <div class="reveal">
    <div ref="slides" class="slides">
      <section data-background-color="#f4f4f4">
        <maps-page :key="page_key" v-if="screen0" ref="maps"></maps-page>
      </section>

      <section data-background-color="#f4f4f4">
        <posting-akun-negatif-page
          :key="page_key"
          v-if="screen1"
          ref="negatif"
        />
      </section>

      <section data-background-color="#f4f4f4">
        <sentimen-page :key="page_key" v-if="screen2" ref="sentimen" />
      </section>

      <section data-background-color="#f4f4f4">
        <posting-deck-page :key="page_key" v-if="screen3" ref="posting" />
      </section>
    </div>
  </div>
</template>

<script>
import Reveal from "reveal.js/js/reveal.js";
import Markdown from "reveal.js/plugin/markdown/markdown.esm.js";

import mapsPage from "@/views/Maps.vue";
import postingAkunNegatifPage from "@/views/PostingAkunNegatif.vue";
import sentimenPage from "@/views/Sentimen.vue";
import postingDeckPage from "@/views/PostingDeck.vue";

export default {
  name: "App",
  components: {
    "maps-page": mapsPage,
    "posting-akun-negatif-page": postingAkunNegatifPage,
    "sentimen-page": sentimenPage,
    "posting-deck-page": postingDeckPage,
  },
  data() {
    return {
      screen0: true,
      screen1: false,
      screen2: false,
      screen3: false,
      interval1: () => {},
      interval2: () => {},
      screen_now: 1,
      page_key: 0,
    };
  },
  mounted: function () {
    this.interval1 = setInterval(() => {
      this.$refs.maps.getDataMap(false);
    }, 300000);
    this.interval2 = setInterval(() => {
      this.$refs.maps.getData(false);
    }, 300000);
    // alert(this.$refs.slides.clientHeight)
    const deck = new Reveal({
      plugins: [Markdown],
    });
    deck.initialize({
      controlsTutorial: true,
      controlsLayout: "bottom-right",
      /*autoSlide: 5000,
        loop: true*/
    });
    let self = this;
    deck.on("slidechanged", (event) => {
      clearInterval(this.interval1);
      clearInterval(this.interval2);
      // event.previousSlide, event.currentSlide, event.indexh, event.indexv
      switch (event.indexh) {
        case 0:
          self.screen0 = true;
          self.screen_now = 1;
          break;
        case 1:
          self.screen1 = true;
          self.screen_now = 2;
          break;
        case 2:
          self.screen2 = true;
          self.screen_now = 3;
          break;
        case 3:
          self.screen3 = true;
          self.screen_now = 4;
          break;
        default:
          // statements_def
          break;
      }
      if (this.screen_now == 1) {
        this.interval1 = setInterval(() => {
          this.$refs.maps.getDataMap(false);
        }, 300000);
        this.interval2 = setInterval(() => {
          this.$refs.maps.getData(false);
        }, 300000);
      } else if (this.screen_now == 2) {
        clearInterval(this.interval1);
        clearInterval(this.interval2);
        this.interval1 = setInterval(() => {
          this.$refs.negatif.getData(false);
        }, 300000);
      } else if (this.screen_now == 3) {
        clearInterval(this.interval1);
        clearInterval(this.interval2);
        this.interval1 = setInterval(() => {
          this.$refs.sentimen.getData(false);
        }, 300000);
      } else if (this.screen_now == 4) {
        clearInterval(this.interval1);
        clearInterval(this.interval2);
        this.interval1 = setInterval(() => {
          this.$refs.posting.getData(false);
        }, 300000);
      }
    });

    this.timer = setInterval(() => {
      this.page_key++;
    }, 900000); //15 menit --> 900000
  },
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~reveal.js/dist/reset.css";
@import "~reveal.js/dist/reveal.css";
@import "~reveal.js/dist/theme/white.css";
@import "./assets/pages/css/pages.css";
@import "./assets/pages/css/bootstrap-social.css";

.djp-header {
  border-bottom: 1px solid #f4f4f4;
  padding: 10px 0px;
  background: #ffffff;
  margin-bottom: 10px;
  height: 8%;
}

.slides {
  margin: 0px !important;
  width: 100% !important;
}

.reveal.center .slides section {
  top: 0px !important;
  overflow: auto;
  min-height: 100% !important;
  display: flex !important;
  flex-flow: column;
  height: 100%;
  max-height: 100%;
}

.container {
  padding: 0px 100px;
  width: 100%;
  display: flex;
}

.sticky {
  position: sticky;
  top: 0px !important;
  /*left: 0;*/
  background: white;
  z-index: 9999 !important;
  padding-top: 10px;
  /*width: 100%*/
}
</style>
