<template>
  <div class="timeline-content no-margin" style="margin-bottom: 5px !important">
    <div
      class="card social-card share full-width"
      style="background-color: #161d31"
    >
      <div
        class="circle"
        data-toggle="tooltip"
        title=""
        data-container="body"
        data-original-title="Label"
      ></div>
      <div class="card-header clearfix pb-0" style="background-color: #161d31">
        <div class="user-pic">
          <img
            alt="Profile Image"
            width="50"
            height="50"
            :data-src-retina="profic"
            :data-src="profic"
            :src="profic"
            @error="imageUrlAlt"
            class="no-margin text-white"
            style="border-radius: 50%; border: 1px solid #f8f9fa"
          />
        </div>
        <h5 align="left" style="text-transform: none" class="text-white">
          <span v-if="post.source == 'twitter'">@</span>{{ post.account }}
        </h5>
        <h6 class="d-flex align-items-center text-white" v-if="simple_meta">
          <!-- <span class="location semi-bold d-flex align-items-center m-l-5" title="Engagement" ><i class="fa fa-share-alt m-r-5"> </i> {{post.engagement_count}}</span> -->
          <!-- <span class="location semi-bold d-flex align-items-center m-l-5" title="Reach" ><i class="fa fa-bullhorn m-r-5"> </i> {{post.esmr.toFixed(2).replace('.',',')}}</span> -->
          <span
            class="
              location
              semi-bold
              d-flex
              align-items-center
              m-l-5
              text-white
            "
            title="Followers"
            ><i class="pg-icon text-white">users</i> {{ post.followers }}</span
          >
          <!-- <span class="location semi-bold d-flex align-items-center m-l-5" title="location"><i class="pg-icon">map</i> {{post.location}}</span> -->
        </h6>
        <h6 class="d-flex align-items-center" v-else>
          <span
            class="
              location
              semi-bold
              d-flex
              align-items-center
              m-l-5
              text-white
            "
            title="Engagement"
            ><i class="fa fa-share-alt m-r-5 text-white"> </i>
            {{ post.engagement_count }}</span
          >
          <span
            class="
              location
              semi-bold
              d-flex
              align-items-center
              m-l-5
              text-white
            "
            title="Reach"
            ><i class="fa fa-bullhorn m-r-5 text-white"> </i>
            {{ 'esmr' in post ? post.esmr.toFixed(2).replace(".", ",") : 0 }}</span
          >
          <span
            class="
              location
              semi-bold
              d-flex
              align-items-center
              m-l-5
              text-white
            "
            title="Followers"
            ><i class="pg-icon text-white">users</i> {{ post.followers }}</span
          >
          <span
            class="
              location
              semi-bold
              d-flex
              align-items-center
              m-l-5
              text-white
            "
            title="location"
            ><i class="pg-icon text-white">map</i> {{ post.location }}</span
          >
        </h6>
      </div>
      <div class="card-description">
        <p class="no-margin text-white" style="text-align: justify">
          {{ post.content }}
        </p>
        <div class="via text-white" align="right">
          via {{ post.source }} pada {{ post.date.$date | dateID }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import default_profic from "@/assets/default.png";
import moment from "moment";
export default {
  name: "postingListComponent",
  props: {
    post: Object,
    simple_meta: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getProfic: function (post) {
      let v_profic;
      switch (post.source) {
        case "twitter":
          v_profic = post.profile_image;
          break;
        default:
          v_profic = default_profic;
          break;
      }
      return v_profic;
    },
    imageUrlAlt: function () {
      event.target.src = default_profic;
    },
  },
  filters: {
    dateID: function (tanggal) {
      return moment.utc(tanggal).format("D MMM YYYY HH:mm");
    },
  },
  computed: {
    profic: function () {
      return this.getProfic(this.post);
    },
  },
};
</script>