<template>
  <div id="sentimen-page" style="height: 100%; background-color: #161d31">
    <!-- START HEADER -->
    <div class="djp-header" align="center" style="background-color: #283046">
      <h4
        style="padding: 0px !important; margin: 0px !important; font-size: 30px"
        class="text-white"
      >
        KEMENTERIAN LUAR NEGERI COMMAND CENTER
      </h4>
      <img
        src="@/assets/logo.png"
        alt=""
        height="50"
        style="
          float: left;
          position: absolute;
          left: 100px;
          top: 0px;
          padding: 0px !important;
          margin: 0px !important;
        "
      />
      <h4
        style="
            float: right;
            position: absolute;
            right: 100px;
            top: 15px;
            padding: 0px !important;
            margin: 0px !important;
            font-size: 24px;
            cursor: pointer;
          "
        class="text-white"
        @click="showModal"
      >
        <i class="fa fa-calendar"></i>
        <span
          style="position: absolute; left: 0; right: 0; top: 11px; color: black; font-size: 10px;"
          id="filter-tanggal-text"
          v-html="filter_tanggal_text"
        ></span>
      </h4>
    </div>
    <!-- END HEADER -->

    <div class="container-fluid" style="height: 90% !important">
      <b-row align-v="stretch" style="height: 100%;" no-gutters>
        <b-col style="background-color: #283046" class="m-r-5">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <h5 align="center" class="no-margin text-white">
                    DATA SEBARAN MENTION
                  </h5>
                  <b-alert
                    class="mt-2"
                    show
                    variant="warning"
                    v-if="mapData == null"
                    >Loading...</b-alert
                  >

                  <!-- <vue-highcharts v-if="chartOptions" type="mapChart" :options="chartOptions"></vue-highcharts> -->

                  <maps-component
                    v-else-if="mapData"
                    :dataset="mapData"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col class="m-l-5">
          <div
            class="card-body"
            style="background-color: #283046; height: 100% !important; overflow-y: auto !important;"
          >
            <div class="row">
              <div class="col-lg-12 mb-5">
                <h5 align="center" class="m-l-0 m-t-0 m-r-0 mb-2 text-white">
                  DAFTAR AKUN DI {{ tableAkunHeader }}
                </h5>
                <b-alert show variant="warning" v-if="tableAkunData == null"
                  >Loading...</b-alert
                >
                <!-- <b-skeleton-table v-else-if="tableAkunData == null" :rows="5" :columns="2" :table-props="{ bordered: true, striped: true }"></b-skeleton-table> -->
                <b-table
                  v-else
                  sticky-header="250px"
                  responsive
                  head-variant="light"
                  :items="tableAkunData"
                  :fields="tableAkunField"
                  class="rounded"
                ></b-table>
              </div>

              <div class="col-lg-12">
                <h5 align="center" class="m-l-0 m-t-0 m-r-0 mb-2 text-white">
                  DAFTAR POSTING TERBARU DI {{ tableAkunHeader }}
                </h5>
                <b-alert show variant="warning" v-if="tablePostData == null"
                  >Loading...</b-alert
                >
                <!-- <b-skeleton-table v-if="tablePostData == null" animation="throb" :rows="5" :columns="2" :table-props="{ bordered: true, striped: true }"></b-skeleton-table> -->
                <b-table
                  v-else
                  sticky-header="250px"
                  responsive
                  :items="tablePostData"
                  :fields="tablePostField"
                  head-variant="light"
                  class="rounded"
                ></b-table>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-modal
      ref="my-modal"
      title="Periode Data"
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      body-text-variant="white"
      header-text-variant="white"
      footer-border-variant="white"
      @ok="handleOK"
    >
      <div class="periode-data p-t-10">
        <div class="row">
          <div class="col">
            <b-button class="m-r-5 m-b-5" @click="setDate('1d')">Hari Ini</b-button>
            <b-button class="m-r-5 m-b-5" @click="setDate('7d')">7 Hari</b-button>
            <b-button class="m-r-5 m-b-5" @click="setDate('30d')">30 Hari</b-button>
            <b-button class="m-r-5 m-b-5" @click="setDate('nm')">Bulan Ini</b-button>
            <b-button class="m-r-5 m-b-5" @click="setDate('3m')">3 Bulan</b-button>
            <b-button class="m-r-5 m-b-5" @click="setDate('1y')">1 Tahun</b-button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-datepicker
              id="tgl-awal"
              dark
              v-model="tgl_awal"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="Tanggal Awal"
              class="mb-2"
            ></b-form-datepicker>
          </div>
          <div class="col">
            <b-form-datepicker
              id="tgl-akhir"
              v-model="tgl_akhir"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="Tanggal Akhir"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import mapsComponent from "@/components/mapsBubble.vue";
import {
  BTable,
  BSkeletonTable,
  BAlert,
  BModal,
  BButton,
  BFormDatepicker,
  BRow,
  BCol,
} from "bootstrap-vue";
import { formatDateParam, getDateDiff } from "@/plugins/helper.js";

export default {
  name: "mapsPage",
  components: {
    "b-table": BTable,
    "b-skeleton-table": BSkeletonTable,
    "b-alert": BAlert,
    "maps-component": mapsComponent,
    "b-modal": BModal,
    "b-button": BButton,
    "b-form-datepicker": BFormDatepicker,
    BRow,
    BCol,
  },
  data() {
    return {
      chartOptions: null,
      mapData: null,
      listAkun: null,
      listPost: null,

      tableAkunField: [
        {
          key: "account",
          label: "Nama Akun",
          thStyle: { backgroundColor: "#161d31", color: "#FFFFFF" },
          tdClass: "bg-secondary text-white",
        },
        {
          key: "mentions",
          label: "Jumlah Posting",
          thStyle: { backgroundColor: "#161d31", color: "#FFFFFF" },
          tdClass: "bg-secondary text-white",
        },
      ],
      tableAkunData: null,
      tableAkunHeader: "",

      tablePostField: [
        {
          key: "account",
          label: "Nama Akun",
          thStyle: { backgroundColor: "#161d31", color: "#FFFFFF" },
          tdClass: "bg-secondary text-white",
        },
        {
          key: "content",
          label: "Konten",
          thStyle: { backgroundColor: "#161d31", color: "#FFFFFF" },
          tdClass: "bg-secondary text-white",
        },
      ],
      tablePostData: null,

      daftar_provinsi: [
        "ACEH",
        "SUMATERA UTARA",
        "SUMATERA BARAT",
        "RIAU",
        "JAMBI",
        "SUMATERA SELATAN",
        "BENGKULU",
        "LAMPUNG",
        "KEPULAUAN BANGKA BELITUNG",
        "KEPULAUAN RIAU",
        "JAKARTA",
        "JAWA BARAT",
        "JAWA TENGAH",
        "DI YOGYAKARTA",
        "JAWA TIMUR",
        "BANTEN",
        "BALI",
        "NUSA TENGGARA BARAT",
        "NUSA TENGGARA TIMUR",
        "KALIMANTAN BARAT",
        "KALIMANTAN TENGAH",
        "KALIMANTAN SELATAN",
        "KALIMANTAN TIMUR",
        "KALIMANTAN UTARA",
        "SULAWESI UTARA",
        "SULAWESI TENGAH",
        "SULAWESI SELATAN",
        "SULAWESI TENGGARA",
        "GORONTALO",
        "SULAWESI BARAT",
        "MALUKU",
        "MALUKU UTARA",
        "PAPUA BARAT",
      ],
      counter: 0,
      filter_tanggal_text: "<i class='fa fa-timer' />",
      tgl_awal: "",
      tgl_akhir: "",

      api: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    getData: function(show_loading = true) {
      let q_param = {
        from: formatDateParam(this.tgl_awal) + " 00:00:00",
        to: formatDateParam(this.tgl_akhir) + " 23:59:59",
      };
      if (show_loading) {
        this.tableAkunData = null;
        this.tablePostData = null;
      }
      this.$http
        .get(
          this.api + "/cc-province-details/" + process.env.VUE_APP_ID_PROJECT,
          { params: q_param }
        )
        .then((response) => {
          // console.log('data map')
          if (response.status == 200) {
            this.listAkun = response.data.account_per_provinces;
            this.listPost = response.data.post_per_provinces;

            this.tableAkunHeader = this.daftar_provinsi[this.counter];
            this.tableAkunData = this.listAkun[
              this.daftar_provinsi[this.counter]
            ];
            this.tablePostData = this.listPost[
              this.daftar_provinsi[this.counter]
            ];
            this.showDataProv();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataMap: function(show_loading = true) {
      let q_param = {
        from: formatDateParam(this.tgl_awal) + " 00:00:00",
        to: formatDateParam(this.tgl_akhir) + " 23:59:59",
      };
      if (show_loading) {
        this.mapData = null;
      }
      this.$http
        .get(this.api + "/cc-map/" + process.env.VUE_APP_ID_PROJECT, {
          params: q_param,
        })
        .then((response) => {
          // console.log('data map map')
          if (response.status == 200) {
            this.mapData = response.data;
          } else {
            this.mapData = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cekData: function() {
      if (
        this.listAkun[this.daftar_provinsi[this.counter]].length == 0 &&
        this.listPost[this.daftar_provinsi[this.counter]].length == 0
      ) {
        this.counter++;
        // this.cekData();
      }
    },
    showDataProv: function() {
      this.counter++;
      if (this.counter < this.daftar_provinsi.length) {
        // console.log(this.counter)
        this.cekData();
        window.setTimeout(() => {
          this.tableAkunHeader = this.daftar_provinsi[this.counter];
          this.tableAkunData = this.listAkun[
            this.daftar_provinsi[this.counter]
          ];
          this.tablePostData = this.listPost[
            this.daftar_provinsi[this.counter]
          ];
          this.showDataProv();
        }, 5000);
      } else {
        // console.log(this.counter)
        this.counter = 0;
        this.tableAkunHeader = this.daftar_provinsi[this.counter];
        this.tableAkunData = this.listAkun[this.daftar_provinsi[this.counter]];
        this.tablePostData = this.listPost[this.daftar_provinsi[this.counter]];
        this.showDataProv();
      }
    },
    showModal: function() {
      this.$refs["my-modal"].show();
    },
    setDefaultDate: function() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      this.tgl_akhir = new Date(today);
      this.tgl_awal = new Date(today);
      this.tgl_awal.setDate(today.getDate() - 7);
      this.filter_tanggal_text = "7D";
    },
    handleOK: function() {
      this.getData();
      this.getDataMap();
      let a = formatDateParam(this.tgl_awal)
      let b = formatDateParam(this.tgl_akhir)
      let day_count = parseInt(getDateDiff(a,b)) + 1
      if(day_count > 99) {
        this.filter_tanggal_text = Math.ceil(day_count/30) + "M"
      }else {
        let date_diff_month = day_count/30
        if(date_diff_month % 1 == 0){
          this.filter_tanggal_text = date_diff_month + "M"
        }else {
          this.filter_tanggal_text = day_count + "D"
        }
      }
    },
    setDate: function(shortcode = '1d'){
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      switch (shortcode) {
        case '1d':
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          break;
        case '7d':
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setDate(today.getDate() - 6);
          break;
        case '30d':
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setDate(today.getDate() - 29);
          break;
        case 'nm':
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setMonth(today.getMonth())
          this.tgl_awal.setDate(1);
          break;
        case '3m':
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setMonth(today.getMonth() - 3)
          this.tgl_awal.setDate(today.getDate() + 1);
          break;
        case '1y':
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setFullYear(today.getFullYear() - 1)
          this.tgl_awal.setDate(today.getDate() + 1);
          break;
        default:
          // statements_def
          break;
      }
    }
  },
  created: function() {
    this.setDefaultDate();
    this.getDataMap();
    this.getData();
  },
};
</script>
<style>
.b-table-sticky-header::-webkit-scrollbar {
  width: 10px;
}
.b-table-sticky-header::-webkit-scrollbar-thumb {
  background-color: #75787f;
  border-radius: 3px;
}
.b-table-sticky-header::-webkit-scrollbar-track {
  background-color: #161d31;
}
.b-table-sticky-header::-webkit-scrollbar-corner {
  background-color: #283046;
}
.slide-background {
  background-color: #283046 !important;
}
.progress {
  background-color: #161d31 !important;
}
</style>

<style>
  .table thead tr th {
    padding-left: 20px !important;
  } 
</style>