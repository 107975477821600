<template>
  <div class="map">
    <div id="mapcontainer" class="rounded">ini maps</div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import * as Highcharts from "highcharts/highmaps.js";
import map from "@/plugins/new-id-all.js";

export default {
  name: "Maps",
  props: {
    dataset: Array,
  },
  data() {
    return {
      data: this.dataset,
    };
  },
  methods: {
    HCconfig: function (nv) {
      const mapConfig = {
        chart: {
          backgroundColor: "#161d31",
          map: map.load_data(),
          height: 80 + "%",
          // events: {
          //   load: function () {
          //     this.mapZoom(0.5, 3000, 9000, 300, 5100);
          //   },
          // },
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: "",
        },
        subtitle: {
          // text:'per Komoditas/Provinsi '
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom",
          },
          enableMouseWheelZoom: false,
        },
        series: [
          {
            type: "map",
            name: "Provinsi",
            color: "#E0E0E0",
            nullColor: "#f8f9fa",
            enableMouseTracking: true,
            data: nv.data,
            dataLabels: {
              // format: '<span class="point-name" data-code="{point.hc_key}">{point.name}</span>',
              formatter: function () {
                return (
                  '<span class="point-name" data-id="' +
                  this.point.hc_key +
                  '">' +
                  this.point.name +
                  "</span>"
                );
              },
              enabled: false,
              useHTML: true,
            },
            tooltip: {
              pointFormat: "{point.name} <br/> Jumlah Mentions: {point.z} ",
            },
            cursor: "pointer",
          },
          {
            type: "mapbubble",
            name: "Jumlah Mentions",
            joinBy: "hc_key",
            // color: $('body').css('--purple'),
            data: nv.data,
            // minSize: 16,
            maxSize: "12%",
            marker: {
              fillOpacity: 0.6,
            },
            tooltip: {
              pointFormat: "{point.name} <br/> Jumlah Mentions: {point.z} ",
            },
            dataLabels: {
              allowOverlap: true,
              format:
                '<span class="point-name" data-code="{point.hc_key}">{point.name}</span>',
              enabled: true,
            },
            point: {
              events: {
                click: function () {
                  // nv.$parent.bubbleClicked(this)
                },
              },
            },
            cursor: "pointer",
            zIndex: 99,
          },
        ],
      };
      return mapConfig;
    },
  },
  mounted: function () {
    if (this.data != null) {
      new Highcharts.mapChart("mapcontainer", this.HCconfig(this));
    }
  },
};
</script>

<style scoped>
.highcharts-point {
  z-index: 999 !important;
}
.highcharts-text-outline {
  stroke-width: 0px;
  color: #f5ecec;
}
/*@import '~highcharts/css/highcharts.css';*/
</style>