<template>
  <div class="timeline-content no-margin" style="margin-bottom: 5px !important">
    <div class="card social-card share full-width">
      <div
        class="circle"
        data-toggle="tooltip"
        title=""
        data-container="body"
        data-original-title="Label"
      ></div>
      <div
        class="card-header clearfix pb-0 rounded"
        style="background-color: #161d31"
      >
        <div class="user-pic">
          <img
            alt="Profile Image"
            width="50"
            height="50"
            :data-src-retina="profic"
            :data-src="profic"
            :src="profic"
            class="no-margin text-white"
            @error="imageUrlAlt"
            style="border-radius: 50%; border: 1px solid #f8f9fa"
          />
        </div>
        <h5 align="left" style="text-transform: none" class="text-white">
          <span v-if="akun.source == 'twitter'" class="text-white">@</span
          >{{ akun.account }}
        </h5>
        <h6 class="d-flex align-items-center text-white">
          {{ akun.source }}
          <span
            class="
              location
              semi-bold
              d-flex
              align-items-center
              m-l-5
              text-white
            "
            v-if="akun.followers"
            title="Followers"
            ><i class="pg-icon">users</i> {{ akun.followers }}</span
          >
          <span
            class="
              location
              semi-bold
              d-flex
              align-items-center
              m-l-5
              text-white
            "
            v-if="akun.location"
            title="Location"
            ><i class="pg-icon">map</i> {{ akun.location }}</span
          >
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import default_profic from "@/assets/default.png";
export default {
  name: "itemList",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      akun: this.item,
    };
  },
  methods: {
    getProfic: function (dataAkun) {
      let v_profic;
      switch (dataAkun.source) {
        case "twitter":
          v_profic = dataAkun.profile_image;
          break;
        default:
          v_profic = default_profic;
          break;
      }
      return v_profic;
    },
    imageUrlAlt: function () {
      event.target.src = default_profic;
    },
  },
  computed: {
    profic: function () {
      return this.getProfic(this.akun);
    },
  },
};
</script>